<template>
    <div class="views-user-registration">
        <h1 class="views-user-registration__title">Подтверждение</h1>
        <ValidationObserver ref="validator" slim>
            <ValidationProvider v-slot="{ errors }" rules="required|max:4">
                <BaseTextField
                    v-model="code"
                    label="Введите код из смс"
                    counter="4"
                    @keyup.enter="confirm"
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
        </ValidationObserver>
        <BaseButton @click="confirm">Подтвердить</BaseButton>
    </div>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';

    import BaseTextField from '../../components/common/BaseTextField';
    import BaseButton from '../../components/common/BaseButton';

    export default {
        name: 'Confirmation',
        metaInfo: {
            title: 'Подтверждение авторизации'
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseTextField,
            BaseButton,
        },
        data: () => ({
            code: '',
        }),
        computed: {
            ...mapState('auth', ['user']),
        },
        methods: {
            isUserProfile() {
                if(this.user.firstname !== '' && this.user.lastname !== '' && this.user.avatar !== '') {
                    this.$router.push({ name: 'home' });
                } else {
                    this.$router.push({ name: 'user-profile-edit' });
                }
            },
            async confirm() {
                const valid = await this.$refs['validator'].validate();
                if(valid) {
                    await store.dispatch('auth/confirm', { code: this.code });
                    await store.dispatch('auth/fetch');
                    await store.dispatch('events/summary');
                    await store.dispatch('events/fetch', {});
                    await this.isUserProfile()
                }
            },
            
        }
    }
</script>

<style lang="scss">
.views-user-registration {
    padding: 24px;
    &__title {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 31px;
        color: #3A4256;
        margin: 24px 0;
    }
    &__sheet {
        position: absolute;
        top: 25%;
        right: 25%;
        display: flex;
    }
}

</style>
